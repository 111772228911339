* {
  margin: 0; 
  padding: 0;
  box-sizing: border-box;
}
:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f2e: #232f2e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
}
.section-heading{
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}
.card-wrapper{
  background-color: white;
  box-shadow: 0 0 10px #000001a;
  padding: 15px;
}
body {
  font-family: 'Rubik', sans-serif;
}
a{
  text-decoration: none;
  display: inline-block;
}
.form-control:focus{
  box-shadow: none;
  border-color: inherit;
}
.gap-10 {
  gap: 10px;
}
.gap-15{
  gap: 15px;
}
.gap-30{
  gap: 30px;
}

.button{
  background-color: var(--color-232f2e);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
}
.button:hover{
  background-color: var(--color-febd69);
  color: #131921;
}
/* Header Part */

.header-top-strip {
  border-bottom: 1px solid var(--color-3b4149);
  background-color: var(--color-131921);
}

.header-upper {
  background-color: var(--color-131921);
}
.header-bottom{
  background-color: var(--color-232f2e);
}
.header-upper .input-group-text{
  background-color: var(--color-febd69);
}
.header-upper .header-upper-links img {
  width: 35px;
  height: 35px;
}
.header-bottom .menu-links a,
.header-bottom .dropdown button{
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3;
  text-transform: uppercase;
}

.header-bottom .dropdown button:focus{
  box-shadow: none;
}
.header-bottom .dropdown-menu {
  background-color: var(--color-131921);
  width: 100%;
  transform: translate3d(0px, 57px, 0px) !important;
}
.header-bottom .dropdown-item{
  padding: 20px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--color-3b4149);
}
.header-bottom .dropdown-item:hover{
  background-color: transparent;
  color: var(--color-febd69) !important;
}
/* Header Part End Here */


/* Footer Part */

footer{
  background-color: var(--color-232f2e);
}
footer:not(:first-child){
  border-top: 1px solid rgba(255,255,255,0.1);
} 
footer .input-group-text {
  background-color: var(--color-232f2e);
  color: white;
  padding: 8px 17px;
}

/* Footer Part End Here */


/* Home Page */
.main-banner-content {
  top: 15%;
  left: 5%;
}
.main-banner-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.main-banner-content h5 {
  font-size: 42px;
  line-height: 54px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}
.main-banner-content p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}

.small-banner-content {
  top: 25%;
  left: 10%;
}
.small-banner-content h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.small-banner-content h5 {
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}
.small-banner-content p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}
.small-banner {
  width: 49%;
}

/* Home Wrapper 2 */

.home-wrapper-2{
  background-color: var(--color-f5f5f7);
}

/* Home Wrapper 2 End Here */

/* Home Wrapper 3 */

.categories {
  background-color: white;
  box-shadow: 0 0 10px #000001a;
  padding: 15px;
}
.categories > div {
  width: 24%;
  padding: 10px 10px 10px 20px;
}
.categories>div>div{
  width: 60%;
}
.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3),
.categories>div:nth-child(4){
 border-bottom: 1px solid var(--color-ededed);
}
.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3){
 border-right: 1px solid var(--color-ededed);
}
.categories>div:nth-child(5),
.categories>div:nth-child(6),
.categories>div:nth-child(7){
 border-right: 1px solid var(--color-ededed);
}
/* Home Wrapper 2 End Here */

/* Home Page End Here */

/* Blog Card */

.blog-card{
  background-color: white;
  border-radius: 10px;
}
.blog-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog-content {
  padding: 15px;
}
.blog-cardp.date{
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-777777);
  padding: 0;
}
.blog-content h5{
  font-size: 18px;
  color: var(--color-131921);
}
.blog-content p.desc{
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}
/* Blog Card End Here */

/* Product Card */

.product-card{
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}
.product-card .product-image{
  height: 270px;
}
.product-card .product-image img{
  object-fit: contain;
}
.product-card .product-image>img:last-child{
  display: none;
}
.product-card:hover .product-image>img:nth-child(1){
  display: none;
}
.product-card:hover .product-image>img:last-child{
  display: block;
}
.product-card:hover .action-bar{
  right: 15px;

}
.product-card .product-details h6{
  color: var(--color-bf4800);
  font-size: 13px;
}

.product-card .product-details h5{
  font-size: 15px;
  color: var(--color-1c1c1b);
}

.product-card .product-details p.price{
  font-size: 16px;
  color: var(--color-1c1c1b);
}
.action-bar{
  top: 10%;
  right: -23px;
  transition: 0.3s;
}
.wishlist-icon{
  top: 4%;
  right: 15px;
}
/* Product Card End Here */

/* Special Product */

.special-product-card{
  padding: 20px 10px;
  border-radius: 10px;
  background-color: white;
}

/* Special Product End Here */

/* Famous Card */

.famous-card .famous-content{
  top: 10%;
  left: 5%;
}

.famous-card img{
  border-radius: 10px;
}

.famous-content h5{
  font-size: 13px;
  line-height: 20px;
  font-weight: 400px;
  color: white;
  margin-bottom: 7px;
  text-transform: uppercase;
}
.famous-content h6{
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  color: white;
}
.famous-content p{
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  color: white;
}

/* Famous Card End Here */

